/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {useDispatch} from 'react-redux';
import * as Yup from 'yup';
import clsx from 'clsx';
import {useHistory} from 'react-router-dom';
import {useFormik} from 'formik';
import toast from 'react-hot-toast';
import * as auth from '../redux/AuthRedux';
import {login} from '../services/AuthService';

const loginEscortSchema = Yup.object().shape({
  username: Yup.string()
    .required('Número de movil es requerido'),
  password: Yup.string()
    .min(4, 'Un mínimo de 4 caracteres')
    .max(20, 'Un máximo de 20 caracteres')
    .required('La contraseña es requerido'),
  role: Yup.string()
    .notRequired(),
})

const initialValues = {
  // username: '+598940009',
  // password: 'test',
  username: '',
  password: '',
  role: 'ROLE_SCORT',
}

const notify = () => toast('Gracias por elegirnos, espero que su día sea de mucho placer');

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-v dation-and-typescript-and-formik-6c342578a20e
*/

export function LoginEscort() {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: loginEscortSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        login(values.username, values.password, values.role)
          .then(({data: {accessToken}}) => {
            setLoading(false);
            dispatch(auth.actions.login(accessToken));
            history.push("/");
            notify();
          })
          .catch(() => {
            setStatus('Los datos de inicio de sesión son incorrectos');
          })
          .finally(() => {
            setLoading(false);
            setSubmitting(false);
          })
      }, 1000)
    },
  })

  return (
   
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_escort_form'
    >
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      
      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Número de movil</label>
        <input
          placeholder='Escribir el número de movil'
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.username && formik.errors.username},
            {
              'is-valid': formik.touched.username && !formik.errors.username,
            }
          )}
          type='text'
          autoComplete='off'
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.username}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Contraseña</label>
            {/* end::Label */}
            {/* begin::Link */}
            {/* <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              Recuperar contraseña?
            </Link> */}
            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          placeholder='Contraseña no menor a 8 carácteres'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <input
          type='hidden'
          autoComplete='off'
          {...formik.getFieldProps('role')}
        />
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_login_escort_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Enviar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Por favor, espere...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
