import {all} from 'redux-saga/effects';
import {combineReducers} from 'redux';

import * as auth from 'app/modules/auth';
import * as filters from 'app/modules/filters';
import * as home from 'app/modules/home';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  filters: filters.reducer,
  home: home.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
  yield all([filters.saga()])
  yield all([home.saga()])
}
