import axios, { CancelTokenSource } from 'axios';
import {AxiosResponseOk} from '../models/AxiosResponsesModel';

const API_URL = process.env.REACT_APP_API_URL || 'api'

// const agent = new https.Agent({  
//   rejectUnauthorized: false
// });

export const LOGIN_URL = `${API_URL}/api/authentication`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/get-user`
export const RECOVERY_PASSWORD_URL = `${API_URL}/api/frontend/client/password`

// Server should return AuthModel
export function login(username: string, password: string, role:string) {
  const result = axios.post<AxiosResponseOk>(LOGIN_URL, {username, password, role});
  return result;
}

export function recoveryPassword(data:any) {
  const result = axios.patch<{result: boolean}>(RECOVERY_PASSWORD_URL, data);
  return result;
}

export function getUserByToken(cancelTokenSource: CancelTokenSource) {
  const result = axios.get<any>(GET_USER_BY_ACCESSTOKEN_URL, { 
    cancelToken: cancelTokenSource.token
  });
  return result;
}
