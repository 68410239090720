import React from 'react'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {RootState} from 'app/setup';
import {shallowEqual, useSelector} from 'react-redux';
import {DrawerChatPage} from 'prom-lib-chat';
import {Content} from './components/Content'
import {MasterInit} from './MasterInit'
import {PageDataProvider} from './core'
import { UserModel } from 'app/modules/auth/models/UserModel';

const firebaseConfig = {
	apiKey: 'AIzaSyDhJFS__bHFtnMb5TqYhdc0d-e_QLUZ-Oc',
    authDomain: 'sexysur-46d0f.firebaseapp.com',
    databaseURL: 'https://sexysur-46d0f-default-rtdb.firebaseio.com',
    projectId: 'sexysur-46d0f',
    storageBucket: 'sexysur-46d0f.appspot.com',
    messagingSenderId: '254755090438',
    appId: '1:254755090438:web:16dab44ee119fd22765387',
    measurementId: 'G-KBPXRZN696',
};

const MasterLayout: React.FC = ({children}) => {
  const isAuthorized: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;	
  return (
    <>
		<PageDataProvider>
			<div className='d-flex flex-column flex-root'>
				<div className='page d-flex flex-row flex-column-fluid'>
					<div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
						<HeaderWrapper />
						<div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
							<div className='post d-flex flex-column-fluid' id='kt_post'>
								<Content>{children}</Content>
							</div>
						</div>
						<Footer />
					</div>
				</div>
			</div>

			<MasterInit />
			<div id='kt_drawer_chat'
				data-kt-drawer='true'
				data-kt-drawer-name='chat'
				data-kt-drawer-activate='true'
				data-kt-drawer-overlay='true'
				data-kt-drawer-max-height='569px'
				data-kt-drawer-width="{default:'300px', 'md': '500px'}"
				data-kt-drawer-direction='end'
				data-kt-drawer-toggle='#kt_drawer_chat_toggle'
				data-kt-drawer-close='#kt_drawer_chat_close'>
				{isAuthorized && <DrawerChatPage user={{id: isAuthorized.slug, name: isAuthorized?.alias, avatar: isAuthorized?.mainImage,}} firebaseConfig={firebaseConfig} />}
				<></>
			</div>
      		<ScrollTop />
    	</PageDataProvider>
    </>
  )
}

export {MasterLayout}
