/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Route, Switch, NavLink, Link, useRouteMatch, Redirect} from 'react-router-dom'
import {KTSVG} from 'app/themes/_sexisur/helpers'
import { LoginClient } from './LoginClient';
import { LoginEscort } from './LoginEscort';

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const {path, url} = useRouteMatch();

  return (
   
    <div
      className='form w-100'
      id='kt_login_form_wrapper'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Iniciar sesi&oacute;n en Sexisur</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          Eres nuevo?{' '}
          <Link to='/registers' className='link-primary fw-bolder'>
            Crear una cuenta
          </Link>
        </div>
      </div>
      {/* begin::Heading */}

      {/* begin::Form Wrapper */}
      <div className='fv-row'>
        <div className='row'>
          <div className="col-lg-12">
            <span className='form-label fs-6 fw-bolder text-dark'>
              Seleccionar tipo de cuenta
            </span>
          </div>
          <div className='col-sm-6'>
            <NavLink to={`${url}/client`} activeClassName="show" className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10">
              <KTSVG path='/media/icons/duotone/Interface/User.svg' className='svg-icon-3x me-5' />
              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Cliente</span>
              </span>
            </NavLink>
          </div>

          <div className='col-sm-6'>
            <NavLink to={`${url}/escort`} activeClassName="show" className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center">
                <KTSVG
                  path='/media/icons/duotone/Interface/Briefcase.svg'
                  className='svg-icon-3x me-5'
                />
                <span className='d-block fw-bold text-start'>
                  <span className='text-dark fw-bolder d-block fs-4 mb-2'>Escort</span>
                </span>
            </NavLink>
          </div>
        </div>
      </div>
      {/* end::Form Wrapper */}

      {/* begin::Routes */}
      <div>
        <Switch>
          <Route path={`${path}/client`} component={LoginClient}/>
          <Route path={`${path}/escort`} component={LoginEscort} />
          <Redirect from={`${path}`} exact={true} to={`${path}/client`} /> 
        </Switch>
      </div>
      {/* end::Routes */}
    </div>
  )
}
