/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSelector } from "react-redux";
import Select from 'react-select';
import { getCitiesFilter } from '../redux/FiltersSelector';
import { colourStylesSelect, themeSelect } from '../helpers';

interface Props {
  applyFilter: (data: any) => void;
}

export const CityFilter: React.FC<Props> = ({applyFilter}) => {
  const [optionsSelect, setOptionsSelect] = React.useState([])
  const citiesChoices: Array<any> = useSelector(getCitiesFilter) as Array<any>;

  return (
    <div className='col-sm-11'>
      <h4>Buscar por ciudad</h4>
      <Select
        styles={colourStylesSelect}
        theme={themeSelect}
        options={citiesChoices}
        placeholder="Seleccionar ciudad"
        defaultValue={optionsSelect}
        value={optionsSelect}
        isClearable={true}
        isMulti={true}
        onChange={(option) => {
          let totalOptions = option.length
          if (totalOptions > 0 && option) {
            setOptionsSelect([...option]);
            // FILTRAR
            const citiesToSend = option.map((item: any) => item.value)
            if (citiesToSend) {
              applyFilter({cities: citiesToSend})
            }
            
          } else {
            setOptionsSelect([]);
            applyFilter({cities: []})
          }
        }}
      />
    </div>
  )
}

export default CityFilter;
