import { createSelector } from "reselect";

import { RootState } from "../../../setup/redux/RootReducer";
// import { FiltersModel } from "../models";

const selectAllFilters = (state: RootState) => state.filters.all;
const selectActiveFilters = (state: RootState) => state.filters.active;


export const getAllFilters = createSelector(
    selectAllFilters,
    (all) => all
);

export const getActiveFilters = createSelector(
    selectActiveFilters,
    (active) => active
);

export const getEyesFilter = createSelector(
    selectAllFilters,
    (all) => all && all?.eyes.map((eye: any) => ({label: eye?.name, value: eye?.id}))
);

export const getEyesFilterById = (value: any) => {
    return createSelector(
        getEyesFilter,
        (eyes) =>  eyes && eyes.filter((item: any) => item?.value === value)
    )
}

export const getEyesFilterOne = (value: any) => {
    return createSelector(
        selectAllFilters,
        (all) =>  all && all?.eyes.filter((item: any) => item?.id === value)
    )
}

export const getHeightsFilter = createSelector(
    selectAllFilters,
    (all) =>  all && all?.heights.map((height: any) => ({label: height?.name, value: height?.id}))
);

export const getHeightsFilterById = (value: any) => {
    return createSelector(
        getHeightsFilter,
        (heights) =>  heights && heights.filter((item: any) => item?.value === value)
    )
}

export const getHeightsFilterOne = (value: any) => {
    return createSelector(
        selectAllFilters,
        (all) =>  all && all?.heights.filter((item: any) => item?.id === value)
    )
}

export const getBodyTypesFilter = createSelector(
    selectAllFilters,
    (all) => all?.bodyType.map((bodyType: any) => ({label: bodyType?.name, value: bodyType?.id}))
);

export const getBodyTypesFilterOne = (value: any) => {
    return createSelector(
        selectAllFilters,
        (all) => all?.bodyType.filter((item: any) => item?.id === value)
    )
}

export const getTopsFilter = createSelector(
    selectAllFilters,
    (all) => all?.tops.map((top: any) => ({label: top?.name, value: top?.id}))
);


export const getTopsFilterMany = (values: any[]) => {
    return createSelector(
        getTopsFilter,
        (tops) => tops?.filter((item: any) => values && values?.includes(item?.value))
    )
}

export const getServicesFilter = createSelector(
    selectAllFilters,
    (all) => all?.services.map((service: any) => ({label: service?.name, value: service?.id, isDisabled: false}))
);

export const getServicesFilterWithName = (values: Array<any>) => {
    return createSelector(
        selectAllFilters,
        (all) => values.map((item: any ) => ({
            ...item, 
            "service": (all?.services.filter((service: any) => service?.id === item.service)[0]?.name)
        }))
    )
}

export const getServicesFilterMany = (values: any[]) => {
    return createSelector(
        getServicesFilter,
        (services) => services?.filter((item: any) => values && values?.includes(item?.value))
    )
}

export const getEthnicsFilter = createSelector(
    selectAllFilters,
    (all) => all?.ethnics.map((ethnic: any) => ({label: ethnic?.name, value: ethnic?.id}))
);

export const getEthnicsFilterById = (value: any) => {
    return createSelector(
        getEthnicsFilter,
        (ethnics) =>  ethnics && ethnics.filter((item: any) => item?.value === value)
    )
}

export const getCitiesFilter = createSelector(
    selectAllFilters,
    (all) => all?.cities.map((city: any) => ({label: city?.name, value: city?.id}))
);

export const getCityFilterOne = (value: any) => {
    return createSelector(
        selectAllFilters,
        (all) => all?.cities.filter((item: any) => item?.id === value)
    )
}

export const getUserTypeScortsFilter = createSelector(
    selectAllFilters,
    (all) => all?.userTypeScorts.map((userType: any) => ({label: userType?.name, value: userType?.id}))
);

export const getUserTypeScortsFilterOne = (value: any) => {
    return createSelector(
        selectAllFilters,
        (all) => all?.userTypeScorts.filter((item: any) => item?.id === parseInt(value))
    )
}

export const getUserTypeScortsFilterMany = (values: any[]) => {
    return createSelector(
        getUserTypeScortsFilter,
        (userTypes) => userTypes?.filter((item: any) => values && values?.includes(parseInt(item?.value)))
    )
}

export const getComplaintReasonsFilter = createSelector(
    selectAllFilters,
    (all) =>  all && all?.complaintReasons.map((complaintReason: any) => ({label: complaintReason?.name, value: complaintReason?.id}))
);
