/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSelector } from "react-redux";
import Select from 'react-select';
import { getEthnicsFilter, getEthnicsFilterById } from '../redux/FiltersSelector';
import { colourStylesSelect, themeSelect } from '../helpers';

interface Props {
  applyFilter: (data: any) => void;
  initialValues: any;
}

export const EthnicFilter: React.FC<Props> = ({applyFilter, initialValues}) => {
  const ethnicsChoices: Array<any> = useSelector(getEthnicsFilter) as Array<any>;
  const initialEthnicsValues: any = useSelector(getEthnicsFilterById(initialValues)) as any;
  const [optionsSelect, setOptionsSelect] = React.useState(initialEthnicsValues[0])

  return (
    <div className='col-lg-4'>
      <h4>Color de piel</h4>
      <Select
        styles={colourStylesSelect}
        theme={themeSelect}
        options={ethnicsChoices}
        placeholder="Seleccionar color de piel"
        defaultValue={optionsSelect}
        value={optionsSelect}
        isClearable={true}
        isMulti={false}
        onChange={(option) => {
          if (option) {
            setOptionsSelect({...option});
            // FILTRAR
            const ethnicToSend = option.value
            if (ethnicToSend) {
              applyFilter({ethnic: ethnicToSend})
            }
            
          } else {
            setOptionsSelect(null);
            applyFilter({ethnic: null})
          }
        }}
      />
    </div>
  )
}

export default EthnicFilter;
