import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {FallbackView} from 'app/themes/_sexisur/partials';
import {HomePage} from '../modules/home/pages/HomePage';

export function PublicRoutes() {
  const RegistersPage = React.lazy(() => import('../modules/registers/pages/RegistersPage'));
  const ClientFormRegisterPage = React.lazy(() => import('../modules/registers/pages/ClientFormRegisterPage'));
  // const EscortFormRegisterPage = React.lazy(() => import('../modules/registers/pages/EscortFormRegisterPage'));
  const AuthPage = React.lazy(() => import('../modules/auth/pages/AuthPage'));
  const EscortProfileDetailPage = React.lazy(() => import('../modules/profiles/escort/pages/EscortProfileDetailPage'));
  
  return (
    <React.Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/' component={HomePage} exact={true} />
        <Route path='/auth' component={AuthPage} />
        <Route path='/registers' component={RegistersPage} />
        <Route path='/register/client' component={ClientFormRegisterPage} />
        {/* <Route path='/register/escort' component={EscortFormRegisterPage} /> */}
        <Route path='/profile/escort/detail/:id' component={EscortProfileDetailPage} />
      </Switch>
    </React.Suspense>
  )
}

