import axios, { CancelTokenSource } from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_FILTERS_URL = `${API_URL}/api/frontend/filters`

// Server should return AuthModel
export function getFilters(cancelTokenSource: CancelTokenSource) {
  const result = axios.get<any>(GET_FILTERS_URL, { 
    cancelToken: cancelTokenSource.token,
  });
  return result;
}
