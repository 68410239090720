/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSelector } from "react-redux";
import Select from 'react-select';
import { getTopsFilter, getTopsFilterMany } from '../redux/FiltersSelector';
import { colourStylesSelect, themeSelect } from '../helpers';

interface Props {
  applyFilter: (data: any) => void;
  initialValues: Array<any>;
}

export const TopFilter: React.FC<Props> = ({applyFilter, initialValues}) => {
  const topsChoices: Array<any> = useSelector(getTopsFilter) as Array<any>;
  const initialTopsValues: Array<any> = useSelector(getTopsFilterMany(initialValues)) as Array<any>;
  const [optionsSelect, setOptionsSelect] = React.useState(initialTopsValues)

  return (
    <div className='col-lg-4 py-4'>
      <h4>Top</h4>
      <Select
        styles={colourStylesSelect}
        theme={themeSelect}
        options={topsChoices}
        placeholder="Seleccionar top"
        defaultValue={optionsSelect}
        value={optionsSelect}
        isClearable={true}
        isMulti={true}
        onChange={(option) => {
          let totalOptions = option.length
          if (totalOptions > 0 && option) {
            setOptionsSelect([...option]);
            // FILTRAR
            const topsToSend = option.map((item: any) => item.value)
            if (topsToSend) {
              applyFilter({tops: topsToSend})
            }
            
          } else {
            setOptionsSelect([]);
            applyFilter({tops: []})
          }
        }}
      />
    </div>
  )
}

export default TopFilter;
