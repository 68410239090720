/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

interface Props {
    email: string;
    subject?: string;
    body?: string;
    children?: React.ReactNode;
}

export const Mailto: React.FC<Props> = ({ email, subject = '', body = '', children }) => {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
    
    return (<a href={`mailto:${email}${params}`} rel="nofollow">
        {children}
    </a>);
};
