import {toAbsoluteUrl} from 'app/themes/_sexisur/helpers'
import {UserModel} from '../models/UserModel'

export class UsersTableMock {
  public static table: Array<UserModel> = [
    {
      id: 1,
      username: 'admin@sexisur.cl',
      mainImage: toAbsoluteUrl('/media/avatars/150-2.jpg'),
      userType: 5,
      role: "ROLE_ADMIN", // Administrator
      alias: 'Administrador',
      slug: 'Sean',
      auth: {
        accessToken: 'access-token-8f3ae836da744329a6f93bf20594b5cc',
        refreshToken: 'access-token-f8c137a2c98743f48b643e71161d90aa',
      },
    },
    {
      id: 2,
      username: 'mario@sexysur.cl',
      mainImage: toAbsoluteUrl('/media/avatars/150-1.jpg'),
      userType: 5,
      role: 'ROLE_CLIENT', // Client
      alias: 'Client',
      slug: 'demo-client',
      auth: {
        accessToken: 'access-token-6829bba69dd3421d8762-991e9e806dbf',
        refreshToken: 'access-token-f8e4c61a318e4d618b6c199ef96b9e55',
      },
    },
    {
      id: 3,
      username: '+598940009',
      mainImage: toAbsoluteUrl('/media/avatars/150-5.jpg'),
      userType: 2,
      role: 'ROLE_SCORT',
      alias: 'Escort',
      slug: 'demo-escort',
      auth: {
        accessToken: 'access-token-d2dff7b82f784de584b60964abbe45b9',
      },
    },
  ]
}
