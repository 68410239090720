/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import { WhatsAppButton } from './WhatsAppButton';
import { CallButton } from './CallButton';
import { FavouriteButton } from './FavouriteButton';
import {RootState} from 'app/setup';
import { setVisit } from '../services/HomeService';
import { EscortCardModel } from '../models/EscortCardModel';
import { UserModel } from 'app/modules/auth/models/UserModel';
import ReactPlayer from 'react-player'
import { useHistory } from 'react-router-dom';

type Props = {
  className: string;
  escort: EscortCardModel;
}

export const EscortCardTwo: React.FC<Props> = ({className, escort}) => {
  const isAuthorized: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
  const history = useHistory();
  const url = `/profile/escort/detail/${escort.id}`;

  const setVisitEscort = () => {
    const data = {
      idScort: escort.id
    }
    setVisit(data);
    history.push(url)
  }

  return (
    <li className={className}>
        {!escort?.isVideo && <div className="top"><img
          onClick={setVisitEscort}
          src={escort?.imageUrl}
          className="main-img img img-responsive"
          alt={escort?.alias}
        /></div>}
        {escort?.isVideo && <div className="player-wrapper">
          <ReactPlayer
            onClick={setVisitEscort}
            className='react-player'
            width='100%'
            height={escort?.top?.slug === 'top-1' ? '450px' : escort?.top?.slug === 'top-2' ? '350' : '250'}
            url={escort?.videoUrl}
            playing={true}
            muted={true}
          />
        </div> }
        {/* onClick={setVisitEscort} */}
        <div className="bottom">
          <h3 className="escort-name">{escort?.alias}</h3>
          <p className="escort-age-city">
            {escort?.age} a&ntilde;os
            {' - '}
            {escort?.city?.name}
          </p>
        </div>
        <CallButton phoneNumber={escort?.phone} />
        <WhatsAppButton phoneNumber={escort?.phone} />
        { isAuthorized && isAuthorized?.role !== "ROLE_SCORT" && <FavouriteButton scort={escort} />} 
    </li>
  )
}
