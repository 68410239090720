/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import clsx from 'clsx';

import {KTSVG} from 'app/themes/_sexisur/helpers';
import {Filters} from '../components/Filters';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3';

export function FiltersMenuPage() {

  return (
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_filter_menu_toggle'
      >
        {/* begin::Menu */}
        <button
          type='button'
          className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
        >
          <KTSVG
            path='/media/icons/duotone/Text/Filter.svg'
            className='svg-icon-5 svg-icon-gray-500 me-1'
          />
          Buscar por
        </button>
        
        <Filters />
        {/* end::Menu */}
      </div>
  )
}

export default FiltersMenuPage;
