import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {UserModel} from '../models/UserModel'
import {getUserByToken} from '../services/AuthService'
import axios from 'axios';

/**
 * Inteface for action with payload
 */
export interface ActionWithPayload<T> extends Action {
  payload?: T
}

/**
 * Interface for Auth state
 */
export interface IAuthState {
  user?: UserModel;
  accessToken?: string;
}

export interface IAuthPayloadState {
  user?: UserModel;
  accessToken?: string;
  idScort?: string;
  escort?: any;
  generalInfo?: any;
}

/**
 * Initial values for Authentication state
 */
const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
}

/**
 * Action Types
 */
export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  // Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  UpdateUser: '[Update User] Action',

  AddUserFavoriteRequested: '[Request Add Favorite User] Action',
  AddUserFavorite: '[Add Favorite User] Action',
  RemoveUserFavoriteRequested: '[Request Remove Favorite User] Action',
  RemoveUserFavorite: '[Remove Favorite User] Action',

}

/**
 * Reducers
 */
export const reducer = persistReducer(
  {storage, key: 'sexisur-fe-auth', whitelist: ['user', 'accessToken']},
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthPayloadState>) => {
    switch (action.type) {

      case actionTypes.Login: {
      // case actionTypes.Register: {
        const accessToken = action.payload?.accessToken
        return {
          accessToken,
          user: undefined
        }
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.UserRequested: {
        return {
          ...state,
          user: undefined
        }
      }

      case actionTypes.UserLoaded:
      case actionTypes.SetUser: {
        const user = action.payload?.user
        return {
          ...state,
          user
        }
      }

      case actionTypes.UpdateUser: {
        if (state.user) {
          const {email, alias} = action.payload?.generalInfo
          return {
            ...state,
            user: {
              ...state.user,
              username: email,
              alias: alias
            }
          }
        }
        return {...state}
      }

      case actionTypes.AddUserFavorite: {
        if (state.user) {
          const escort = action.payload?.escort
          const newScortsFavorites = state?.user?.scortsFavorites;
          return {
            ...state,
            user: {
              ...state.user,
              scortsFavorites: newScortsFavorites && newScortsFavorites.concat(escort)
            }
          }
        }
        return {...state}
      }

      case actionTypes.RemoveUserFavorite: {
        if (state.user) {
          const idEscort = action.payload?.idScort
          if (idEscort) {
            const newScortsFavorites = state?.user?.scortsFavorites?.filter((item: any) => item.id !== idEscort);
            return {
              ...state,
              user: {
                ...state.user,
                scortsFavorites: newScortsFavorites
              }
            }
          }
        } 
        return {...state}
      }

      default:
        return state
    }
  }
)

/**
 * ACTIONS TO DISPATCH
 */
export const actions = {
  login: (accessToken: string) => ({type: actionTypes.Login, payload: {accessToken}}),
  // register: (accessToken: string) => ({
  //   type: actionTypes.Register,
  //   payload: {accessToken},
  // }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
  updateUser: (generalInfo: any) => ({type: actionTypes.UpdateUser, payload: {generalInfo}}),

  setAddUserFavourite: (escort: any) => ({type: actionTypes.AddUserFavorite, payload: {escort}}),
  requestDeleteUserFavourite: (idScort: number) => ({type: actionTypes.RemoveUserFavoriteRequested, payload: {idScort}}),
  setDeleteUserFavourite: (idScort: number) => ({type: actionTypes.RemoveUserFavorite, payload: {idScort}}),
}

/**
 * SAGA SIDE EFFECTS
 */
export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser())
  // })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const cancelToken = axios.CancelToken;
    const cancelTokenSource = cancelToken.source();
    const {data} = yield getUserByToken(cancelTokenSource)
    yield put(actions.fulfillUser(data.user))
  })
}
