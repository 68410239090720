/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ForgotPassword} from '../components/ForgotPassword'
import {Login} from '../components/Login'

function AuthPage() {

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='w-lg-500px rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Switch>
            <Route path='/auth/login' component={Login} />
            <Route path='/auth/forgot-password' component={ForgotPassword} />
            <Redirect from='/auth' exact={true} to='/auth/login' />
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default AuthPage;