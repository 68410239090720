import React from "react"
import {Helmet} from "react-helmet"

interface Props {
    title: string;
    meta: Array<any>;
}

export const SEO: React.FC<Props> = ({title, meta = []}) => {
 return(
  <Helmet title = {title}
          htmlAttributes={{ lang: "en" }}
          meta={meta}
   />
  )
}
