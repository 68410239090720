import React from 'react'
import axios from 'axios';
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from 'app/themes/_sexisur/layout/core'
import * as auth from './AuthRedux'
import {getUserByToken} from '../services/AuthService'
import {RootState} from 'app/setup'

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: React.FC<PropsFromRedux> = (props) => {
  const didRequest = React.useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = React.useState(true)
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)

  // We should request user by authToken before rendering the application
  React.useEffect(() => {
    const cancelToken = axios.CancelToken;
    const cancelTokenSource = cancelToken.source();
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(cancelTokenSource)
          dispatch(props.fulfillUser(data.user))
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (accessToken) {
      requestUser()
    } else {
      dispatch(props.logout())
      setShowSplashScreen(false)
    }
    return () => {
      cancelTokenSource.cancel("Axios request cancelled");
    };
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
