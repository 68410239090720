/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {UserModel} from 'app/modules/auth/models/UserModel';
import {RootState} from 'app/setup';

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      {
        user ? <>
          <div className='menu-item px-3'>
            <div className='menu-content d-flex align-items-center px-3'>
              <div className='symbol symbol-50px me-5'>
                <img src={user?.mainImage} alt={user?.alias} />
                {/* <img alt={user.username} src={user.avatar} /> */}
              </div>
              {}
              <div className='d-flex flex-column'>
                <div className='fw-bolder d-flex align-items-center fs-5'>
                  Bienvenido
                  <br/>
                  {user.alias}
                  {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
                </div>
              </div>
            </div>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-5'>
            {user.role === 'ROLE_CLIENT' && <Link to={'/profile/client'} className='menu-link px-5'>
              Mi Perfil
            </Link>}
            {user.role === 'ROLE_SCORT' && <Link to={`/profile/escort/edit/${user.id}`} className='menu-link px-5'>
              Mi Perfil
            </Link>}
          </div>

          <div className='menu-item px-5'>
            <Link to='/logout' className='menu-link px-5'>
              Desconectarse
            </Link>
          </div>
        </> : <>
          <div className='menu-item px-5'>
            <Link to='/auth' className='menu-link px-5'>
              Iniciar sesión
            </Link>
          </div>
          <div className='menu-item px-5'>
            <Link to='/registers' className='menu-link px-5'>
              Registrarse
            </Link>
          </div>
        </>
      }
    </div>
  )
}

export {HeaderUserMenu}
