import axios, { CancelTokenSource } from 'axios';
// import https from 'https';

const API_URL = process.env.REACT_APP_API_URL || 'api'

// const agent = new https.Agent({  
//   rejectUnauthorized: false
// });

export const GET_ESCORTS_URL = `${API_URL}/api/frontend/home`
export const FAVOURITE_URL = `${API_URL}/api/frontend/client/favorite`
export const VISIT_URL = `${API_URL}/api/frontend/visit`

export function getEscorts(cancelTokenSource: CancelTokenSource, data?: any) {
  const result = axios.post<any>(GET_ESCORTS_URL, data, { 
    cancelToken: cancelTokenSource.token,
  });
  return result;
}

export function loadMoreEscorts(data: any, cancelTokenSource: CancelTokenSource) {
  const result = axios.post<any>(GET_ESCORTS_URL, data, { 
    cancelToken: cancelTokenSource.token,
  });
  return result;
}

export function addFavourite(idScort: number) {
  const result = axios.post<any>(FAVOURITE_URL, {"idScort": idScort});
  return result;
}

export function deleteFavourite(idScort: number) {
  const result = axios.delete<any>(`${FAVOURITE_URL}/${idScort}`);
  return result;
}

export function setVisit(data?: any) {
  const result = axios.post<any>(VISIT_URL, data);
  return result;
}
