/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react';
import {Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux';
import {MasterLayout} from 'app/themes/_sexisur/layout/MasterLayout';
import {PublicRoutes} from './PublicRoutes';
import {PrivateRoutes} from './PrivateRoutes';
import {RootState} from 'app/setup';
import {ErrorsPage} from 'app/modules/errors/ErrorsPage';
import { UserModel } from 'app/modules/auth/models/UserModel';

const Routes: React.FC = () => {
  const isAuthorized: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;

  return (
    <Switch>
      <Route path='/error' component={ErrorsPage} />
      <MasterLayout>
        <PublicRoutes />
        {isAuthorized && <PrivateRoutes />}
      </MasterLayout>
    </Switch>
  )
}

export {Routes}
