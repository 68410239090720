import {FiltersModel} from '../models/FiltersModel'

export class FiltersTableMock {
  public static table: FiltersModel = {
    "eyes": [
        {
            "id": "35a01d52bb69a861c706057b219c21e6",
            "name": "Ámbar",
            "slug": "ambar"
        },
        {
            "id": "25b15b9ab533ab19ce3b9606fb9f710d",
            "name": "Avellana",
            "slug": "avellana"
        },
        {
            "id": "9962937156e2dc7efc3a062c11db9dcf",
            "name": "Azul",
            "slug": "azul"
        },
        {
            "id": "5070a59bc6798b0a2860fd18bafc6b5a",
            "name": "Café",
            "slug": "cafe"
        },
        {
            "id": "13cbd3c8d8855c2a9ec0c09f7392f7e6",
            "name": "Gris",
            "slug": "gris"
        },
        {
            "id": "8fcdafc7b4fbd29a17ede64c3b0e0048",
            "name": "Oscuro",
            "slug": "oscuro"
        },
        {
            "id": "28676e513c30ca5f9e3604b727315c74",
            "name": "Otros",
            "slug": "otros"
        },
        {
            "id": "c03810eb09647fe4b1f461f5b5308529",
            "name": "Verde",
            "slug": "verde"
        }
      ],
      "heights": [
        {
            "id": "e2f9f7a1837154936cdbef68f214125b",
            "name": "Alta",
            "slug": "alta"
        },
        {
            "id": "fd549a1468de15ec4d6ef2cf5dac9b88",
            "name": "Altura promedio",
            "slug": "altura-promedio"
        },
        {
            "id": "c64dafd2e6462ac3a9085b060eeb9f56",
            "name": "Baja",
            "slug": "baja"
        }
      ],
      "bodyType": [
        {
            "id": "aa0006f1c388b299b03274208f4566b3",
            "name": "Delgada",
            "slug": "delgada"
        },
        {
            "id": "0ea8fed53b3c97adca9d5010190144e7",
            "name": "Gordita",
            "slug": "gordita"
        },
        {
            "id": "b0a3448586b6417965dff0d4cf9a660d",
            "name": "Otros",
            "slug": "otros"
        },
        {
            "id": "0c1fdf7c790a56eed4d919de3fe7abfb",
            "name": "Promedio",
            "slug": "promedio"
        }
      ],
      "tops": [
        {
            "id": "2ff09eee316152bbe3aa7eb5060aca8b",
            "name": "Top 1",
            "slug": "top-1",
            "position": 1
        },
        {
            "id": "b8f3fffbae4ac136574ab29c2bdc2e20",
            "name": "Top 2",
            "slug": "top-2",
            "position": 2
        },
        {
            "id": "34094dd82e1b8c606e8e6caddf9c6b16",
            "name": "Top 3",
            "slug": "top-3",
            "position": 3
        }
      ],
      "services": [
        {
            "id": "e85a8976407402fb5225165759a7af11",
            "name": "Beso en boca",
            "slug": "beso-en-boca"
        },
        {
            "id": "575160185f961dd11b162079a45f2cea",
            "name": "Beso negro",
            "slug": "beso-negro"
        },
        {
            "id": "e0c676a5b03c82b705430dd6cdaba9bb",
            "name": "Copro",
            "slug": "copro"
        },
        {
            "id": "9fcdf5b6408908f9d6de16821438ba2b",
            "name": "Despedidas de soltero/soltera",
            "slug": "despedidas-de-soltero-soltera"
        },
        {
            "id": "389a55aca18148b3e1c5c99b1d34668c",
            "name": "Doble Penetración",
            "slug": "doble-penetracion"
        },
        {
            "id": "8332c5f1c8b6c035d37dc154c0222135",
            "name": "Footjob",
            "slug": "footjob"
        },
        {
            "id": "0be3815fc30cb83478b8d7a28834cf7f",
            "name": "Juguetes Eróticos",
            "slug": "juguetes-eroticos"
        },
        {
            "id": "aa06466dfe5fef2baca542b1924922e3",
            "name": "Lencería",
            "slug": "lenceria"
        },
        {
            "id": "8f5aa60167e8c7050a8170f5ee788f50",
            "name": "Lésbico Real",
            "slug": "lesbico-real"
        },
        {
            "id": "add32cb5c776bccebc3e973b760c4f4d",
            "name": "Salidas a domicilio",
            "slug": "salidas-a-domicilio"
        },
        {
            "id": "e6bcd926dd34f3fe02384079ecd7c4d4",
            "name": "Salidas a eventos",
            "slug": "salidas-a-eventos"
        },
        {
            "id": "94336e8735b4d073b20b8dcadb9d9fc7",
            "name": "Salidas a fiestas",
            "slug": "salidas-a-fiestas"
        },
        {
            "id": "e45c32825a082a32a20baa1835094c56",
            "name": "Salidas a hoteles",
            "slug": "salidas-a-hoteles"
        },
        {
            "id": "b8c278eb9f97b44facd69ecfc173fce6",
            "name": "Sexo oral acabar en cuerpo",
            "slug": "sexo-oral-acabar-en-cuerpo"
        },
        {
            "id": "09d8cc459b49bf2531efbe1dbc5a0e6b",
            "name": "Sexo oral acabar en rostro",
            "slug": "sexo-oral-acabar-en-rostro"
        },
        {
            "id": "70bee8a0fe1f96379c64fafed11ad212",
            "name": "Sexo oral al natural",
            "slug": "sexo-oral-al-natural"
        },
        {
            "id": "4003ebb140b891f208101bccff8819db",
            "name": "Sexo oral completo",
            "slug": "sexo-oral-completo"
        },
        {
            "id": "6d4d28087cabcadb2bd4c82361f6e621",
            "name": "Sexo oral con condón",
            "slug": "sexo-oral-con-condon"
        },
        {
            "id": "1905666667dc231e476ee1b03fc41ea7",
            "name": "Sexo oral garganta profunda",
            "slug": "sexo-oral-garganta-profunda"
        }
      ],
      "ethnics": [
        {
            "id": "3a70a0bc8ff5c66296028536607a90f5",
            "name": "Asiaticas",
            "slug": "asiaticas"
        },
        {
            "id": "7726178358383eb02dd8334d7a2f26a7",
            "name": "Blancas",
            "slug": "blancas"
        },
        {
            "id": "912c981534c42a68c2c4b34a74c31069",
            "name": "Latinas",
            "slug": "latinas"
        },
        {
            "id": "d6089fb0fd1f9d88d0cecf30e27d6c64",
            "name": "Morenas",
            "slug": "morenas"
        }
      ],
      "cities": [
        {
            "id": "0c37689eddf60ee9af29943f2e66e470",
            "name": "Ancud",
            "slug": "ancud"
        },
        {
            "id": "cf4983e79320b1ab24961e771379ac70",
            "name": "Angol",
            "slug": "angol"
        },
        {
            "id": "c67d1e17b2984e1790837df84b063a71",
            "name": "Antofagasta",
            "slug": "antofagasta"
        },
        {
            "id": "af83c8a825dc83640ba9f0d8b886aaf8",
            "name": "Arica",
            "slug": "arica"
        },
        {
            "id": "1224cf8d13a80ba6c3e253c6d5ce449c",
            "name": "Calama",
            "slug": "calama"
        },
        {
            "id": "992a7d7db03942b6b6153505e6f010fa",
            "name": "Castro",
            "slug": "castro"
        },
        {
            "id": "246e0b6d9757b3191c9373052880d67d",
            "name": "Chaitén",
            "slug": "chaiten"
        },
        {
            "id": "eb4ae56b94e2988b7bd72de7cb20aaf5",
            "name": "Chillán",
            "slug": "chillan"
        },
        {
            "id": "fe05a844360cf672676f022700c30c07",
            "name": "Cochamo",
            "slug": "cochamo"
        },
        {
            "id": "4829f79b6dc612656d5d793acec399f4",
            "name": "Cochrane",
            "slug": "cochrane"
        },
        {
            "id": "9d45a6edc33756b1422a7471b3f2cdd0",
            "name": "Concepción",
            "slug": "concepcion"
        },
        {
            "id": "709f1ecf84faadbd3349651526603a9c",
            "name": "Copiapó",
            "slug": "copiapo"
        },
        {
            "id": "67292e9312cbcdfdbeb991b4a4d85bb8",
            "name": "Corral",
            "slug": "corral"
        },
        {
            "id": "b6bffe67b710c579606afa8815a40610",
            "name": "Coyhaique",
            "slug": "coyhaique"
        },
        {
            "id": "7b95ca9086042e8cf05021e4feaffeeb",
            "name": "Curacautin",
            "slug": "curacautin"
        },
        {
            "id": "f1266500c86a28d497cf1cfc5615e5cb",
            "name": "Curicó",
            "slug": "curico"
        },
        {
            "id": "1b2eb1afb94a44e629ef5a373a0566ec",
            "name": "Dalcahue",
            "slug": "dalcahue"
        },
        {
            "id": "1d8a97be58cb4cac114cc7aa9ae53178",
            "name": "Frutillar",
            "slug": "frutillar"
        },
        {
            "id": "d77e19175010880cb004caa2bc8b1122",
            "name": "Iquique",
            "slug": "iquique"
        },
        {
            "id": "a34fe9486705eecb0742b232d1691ef9",
            "name": "La Serena",
            "slug": "la-serena"
        },
        {
            "id": "fdcccdb1ab9454bebdfd9a0853a82b3f",
            "name": "Los Angeles",
            "slug": "los-angeles"
        },
        {
            "id": "250a5a9a2b3f32cc855930094cf102bd",
            "name": "Lota",
            "slug": "lota"
        },
        {
            "id": "1d1d8cb0e6fdb59552f318dae2e0a3df",
            "name": "Natales",
            "slug": "natales"
        },
        {
            "id": "36a781e23f8405478f6c567d0afd9992",
            "name": "Niebla",
            "slug": "niebla"
        },
        {
            "id": "2220b6ba6f607a09456be64776f6dcff",
            "name": "Olmué",
            "slug": "olmue"
        },
        {
            "id": "1b14fd8f6ce6d150f3058cc05eea9ff3",
            "name": "Osorno",
            "slug": "osorno"
        },
        {
            "id": "818f574d54368f786a7602fa2eecd767",
            "name": "Padre las Casas",
            "slug": "padre-las-casas"
        },
        {
            "id": "fa9062bb378f0100a5a0e56eeaafeefd",
            "name": "Porvenir",
            "slug": "porvenir"
        },
        {
            "id": "8de131de0af388165a554cc1a77e2670",
            "name": "Providencia",
            "slug": "providencia"
        },
        {
            "id": "35c195ecda5d8bb8f3cfcae1de3a28bc",
            "name": "Pucon",
            "slug": "pucon"
        },
        {
            "id": "da13a2110142fa0e4cc25917d2e4c759",
            "name": "Puerto Aysen",
            "slug": "puerto-aysen"
        },
        {
            "id": "2eb1b5f1b74951653397ce8006eb5821",
            "name": "Puerto Chacabuco",
            "slug": "puerto-chacabuco"
        },
        {
            "id": "d1401d78ebe61c9e082838a8e5dd841e",
            "name": "Puerto Montt",
            "slug": "puerto-montt"
        },
        {
            "id": "5e76ab20c7634cee27db9c07f1d3b1f7",
            "name": "Puerto Natales",
            "slug": "puerto-natales"
        },
        {
            "id": "729f908bec2d55a0089af378f7d1e33e",
            "name": "Puerto Octay",
            "slug": "puerto-octay"
        },
        {
            "id": "caa3ed12d33f6648ccbebf8dccd7edeb",
            "name": "Puerto Varas",
            "slug": "puerto-varas"
        },
        {
            "id": "1323129d7b895d9de38fcbe075449a91",
            "name": "Puerto Williams",
            "slug": "puerto-williams"
        },
        {
            "id": "c977956e62530ff77e2e223857d5090f",
            "name": "Punta Arenas",
            "slug": "punta-arenas"
        },
        {
            "id": "7e7f1a0973b51bd9bda3b34aa22fd59a",
            "name": "Quellón",
            "slug": "quellon"
        },
        {
            "id": "681418b6222be98f15092ed56dc4b2f2",
            "name": "Rancagua",
            "slug": "rancagua"
        },
        {
            "id": "4698589c9261532b14f2b8d6d1eb44e6",
            "name": "San Fernando",
            "slug": "san-fernando"
        },
        {
            "id": "7c387fbf0f95b022ad1466e48766fc85",
            "name": "San Pedro de Atacama",
            "slug": "san-pedro-de-atacama"
        },
        {
            "id": "9924f4af05e82acbf85db8a31d756921",
            "name": "Santiago de Chile",
            "slug": "santiago-de-chile"
        },
        {
            "id": "e8f9cda8ab33c190969818a47cd29823",
            "name": "Talca",
            "slug": "talca"
        },
        {
            "id": "916eee8afd517d1b3781084f41977bf8",
            "name": "Talcahuano",
            "slug": "talcahuano"
        },
        {
            "id": "888fa397528d0c2b93a9cf9f68544259",
            "name": "Temuco",
            "slug": "temuco"
        },
        {
            "id": "e3a8f287caa5d703640682b874355808",
            "name": "Tome",
            "slug": "tome"
        },
        {
            "id": "40e8268783dda077ac5bb3c15c97a7aa",
            "name": "Valdivia",
            "slug": "valdivia"
        },
        {
            "id": "184f7faa8e224fca8fffc355b30c277b",
            "name": "Valparaíso",
            "slug": "valparaiso"
        },
        {
            "id": "94ab2c68ea2b104bd9b83fcb2ea55218",
            "name": "Villarrica",
            "slug": "villarrica"
        },
        {
            "id": "5e4025a3fd073f92da75c3d9f9fe7c02",
            "name": "Viña del Mar",
            "slug": "vina-del-mar"
        }
      ],
      "complaintReasons": [
        {
            "id": "7009a1cc2f2bfcfbc0f5a2afb0f1bd6e",
            "name": "Fotos falsas/Fotos robadas",
            "slug": "fotos-falsas-fotos-robadas"
        },
        {
            "id": "7467e19d96da2022e11e10681a532e2f",
            "name": "Fotos falsas/Fotos robadas",
            "slug": "fotos-falsas-fotos-robadas-1"
        },
        {
            "id": "58bbbd5e79ba0caff54e8b549379b137",
            "name": "Otros",
            "slug": "otros-1"
        },
        {
            "id": "8e99ba80b4fcb92e502e4847df41ae2c",
            "name": "Otros",
            "slug": "otros"
        }
      ],
      "userTypeScorts": [
        {
            "id": 3,
            "name": "Gigoló",
            "slug": "gigolo"
        },
        {
            "id": 1,
            "name": "Scort",
            "slug": "scort"
        },
        {
            "id": 2,
            "name": "Trans/Transvesti",
            "slug": "trans-transvesti"
        }
      ],
      "userTypeForClient": [
        {
            "id": 4,
            "name": "Cliente",
            "slug": "cliente"
        }
    ]
  }
}
