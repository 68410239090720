// import {EscortCard} from '../components/EscortCard';

export function removeNullProperties(obj: any) {
    Object.keys(obj).forEach(key => {
      let value = obj[key];
      let hasProperties = value && Object.keys(value).length > 0;
      
      if (!hasProperties) {
        if (value !== null || value !== undefined || value.length === 0) {
          delete obj[key];
        }
      }
    });
    return obj;
}

export const renderTemplateOne = () => {
    // const top1 = moreScorts?.filter((escort: EscortCardModel) => escort.top.slug === "top-1").sort(function (x, y) {
    //   return x.orderInTop - y.orderInTop;
    // })
    // const escortsCardTopOne = top1 && top1.map((escort: EscortCardModel) =>
    // <EscortCard 
    //   key={escort?.phone}
    //   className={clsx({'escort-card top-one': true,})}
    //   escort={escort}
    //   />
    // );
    // const top2 = moreScorts?.filter((escort: EscortCardModel) => escort.top.slug === "top-2").sort(function (x, y) {
    //   return x.orderInTop - y.orderInTop;
    // })
    // const escortsCardTopTwo = top2 && top2.map((escort: EscortCardModel) =>
    // <EscortCard 
    //   key={escort?.phone}
    //   className={clsx({'escort-card top-two': true,})}
    //   escort={escort}
    // />
    // );
    // const top3 = moreScorts?.filter((escort: EscortCardModel) => escort.top.slug === "top-3").sort(function (x, y) {
    //   return x.orderInTop - y.orderInTop;
    // })
    // const escortsCardTopThree = top3 && top3.map((escort: EscortCardModel) =>
    //   <EscortCard 
    //     key={escort?.phone}
    //     className={clsx({'escort-card top-three': true,})}
    //     escort={escort}
    //   />
    // );
    // return (
    //   <>
    //     <div className="custom-row">{escortsCardTopOne}</div>)
    //     <div className="custom-row">{escortsCardTopTwo}</div>)
    //     <div className="custom-row">{escortsCardTopThree}</div>)
    //   </>
    // )
    return;
}