/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSelector } from "react-redux";
import Select from 'react-select';
import { getHeightsFilter, getHeightsFilterById } from '../redux/FiltersSelector';
import { colourStylesSelect, themeSelect } from '../helpers';

interface Props {
  applyFilter: (data: any) => void;
  initialValues: any;
}

export const HeightFilter: React.FC<Props> = ({applyFilter, initialValues}) => {
  const heightsChoices: Array<any> = useSelector(getHeightsFilter) as Array<any>;
  const initialHeightValues: any = useSelector(getHeightsFilterById(initialValues)) as any;
  const [optionsSelect, setOptionsSelect] = React.useState(initialHeightValues[0])

  return (
    <div className='col-lg-4'>
      <h4>Altura</h4>
      <Select
        styles={colourStylesSelect}
        theme={themeSelect}
        options={heightsChoices}
        placeholder="Seleccionar altura"
        defaultValue={optionsSelect}
        value={optionsSelect}
        isClearable={true}
        isMulti={false}
        onChange={(option) => {
          if (option) {
            setOptionsSelect({...option});
            // FILTRAR
            const heightToSend = option.value
            if (heightToSend) {
              applyFilter({height: heightToSend})
            }
            
          } else {
            setOptionsSelect(null);
            applyFilter({height: null})
          }
        }}
      />
    </div>
  )
}

export default HeightFilter;
