/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {toAbsoluteUrl} from 'app/themes/_sexisur/helpers'
import {Link} from 'react-router-dom'
import {KTSVG} from 'app/themes/_sexisur/helpers'
import {shallowEqual, useSelector} from 'react-redux';
import { UserModel } from 'app/modules/auth/models/UserModel'
import { RootState } from 'app/setup';
import { Mailto } from './Mailto';

const Footer: React.FC = () => {
  const isAuthorized: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;

  return (
    <div className="mt-5 pt-5 pb-5 footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-xs-12 about-company">
            <h2>
              <img src={`${toAbsoluteUrl('/media/logos/logo-white.svg')}`} height="70" alt="Sexisur"
                    loading="lazy" />
            </h2>
            <p className="pr-5 text-white-50">
              Sitio sobre escorts y masajistas para mayores de 18 años.
              No poseemos relacion laboral con los anunciantes y nos limitamos exclusivamente a brindar un servicio publicitario.
              Somos una plataforma de publicidad legalmente establecida.
              Nos reservamos el derecho a publicación. Sugerimos no depositar por adelantado, ni entregar datos personales, que puedan afectar su bienestar.
            </p>
            <p><a href="#"><i className="fa fa-facebook-square mr-1"></i></a><a href="#"><i className="fa fa-linkedin-square"></i></a></p>
          </div>
          {!isAuthorized && 
           <div className="col-lg-3 col-xs-12 links">
              <h4 className="mt-lg-0 mt-sm-3">Enlaces</h4>
                <ul className="m-0 p-0">
                  <li>- <Link to={`/auth`}>Iniciar sesi&oacute;n</Link></li>
                  <li>- <Link to={`/registers`}>Registrarse</Link></li>
                </ul>
            </div>
          }
          <div className="col-lg-4 col-xs-12 location">
            <h4 className="mt-lg-0 mt-sm-4">Contactos</h4>
            <p className="mb-0">
              <KTSVG
                path='/media/icons/duotone/Devices/Phone.svg'
                className='svg-icon-2x me-5'
              />(+56) 9-7645-4142
            </p>
            <br />
            <p className="mb-0">
              <KTSVG
                path='/media/icons/duotone/Communication/Mail.svg'
                className='svg-icon-2x me-5'
              />
              <Mailto email="support@sexisur.cl">
                support@sexisur.cl
              </Mailto>
            </p>
            <p className="mb-0">
              <KTSVG
                path='/media/icons/duotone/Communication/Mail.svg'
                className='svg-icon-2x me-5'
              />
              <Mailto email="clientes@sexisur.cl">
                clientes@sexisur.cl
              </Mailto>
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col copyright">
            <p className="fw-bold me-2">
              <small className="text-white-50">
                {new Date().getFullYear()} &copy; Copyright Sexisur
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Footer}
