import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from './themes/_sexisur/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from './themes/_sexisur/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import FiltersInit from 'app/modules/filters/redux/FiltersInit';
import {Routes} from './routing/Routes'
import { Toaster } from 'react-hot-toast';
import {SEO} from 'app/themes/_sexisur/layout/components/meta/Seo';

type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  const titleMeta = "Sexisur. El mejor portal de escorts putas y dama de compañía de Chile."
  const meta = [
    {
      name: `description`,
      content: "Encuentra aqui novedades sobres chicas prostitutas exclusivas VIP si buscas relaciones ocasionales ricos sexo, sexo brutal sexo erótico, sexo amateur disfrutarás del mejor Chile placer, date una escapada y mira las chicas de skokka y de la estokada  tambien dando lo mejor de si en sexisur deja que te complazcan colegialas eróticas, maduras colombianas, maduras chilenas,  cubanas ,  gorditas cachonda MILF culonas chicas eróticas chicas putonas Chile divas Petardas chilenas minas calientes milf chilenas Chicas operadas principiantes tetonas culonas enciende tu deseo sexual con sexy chicas rubias calientes Hot milf , Deseosas de gozar con tu compañía disfruta del sexo rico casero con negrascalientes gordas maduras mega culonas con sus juguetes eróticos video llamadas servicio Sex Cam servicios virtuales, Tríos con culonas chilenas o culonas argentinas, Chilenas con anal orgías chilenas con colombianas, calientes culonas venezolanas tetonas culonas ricas venezolanas, recién llegadas tetonas flacas chicas teniendo sexo americana corporal americana real contacta sus números de WhatsApp para el Chat, Chat erótico goza de un placentero y ardiente encuentro sexual chile caliente disfruta de las mejores mamadas de nenas calientes con tetas naturales y perfectas operadas,  perfectas del mejor sexo Anal profundo,  Greco,  cambio de roles ambos sexos,  atención a parejas heteros, heterocuriosos, Swinger Bellas colombianas, busca entre los cientos de anuncios eróticos y placer.",
    } 
  ]

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
            <FiltersInit>
              <SEO title={titleMeta}
                meta={meta}
              />
              <Routes />
              <Toaster position="bottom-center" />
            </FiltersInit>
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
