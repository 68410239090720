/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import {
  getTotal,
} from "../redux/HomeSelector";
import { removeNullProperties } from '../helpers';
import { EscortCardModel } from '../models/EscortCardModel';
import { loadMoreEscorts, getEscorts } from '../services/HomeService';
import { actions } from '../redux/HomeRedux';
import { InfiniteScroll } from '../components/InfiniteScroll';
import { FiltersActiveModel } from 'app/modules/filters/models';
import { Loading } from 'app/themes/_sexisur/layout/components/loading/Loading';
import { getActiveFilters } from 'app/modules/filters/redux/FiltersSelector';
import { MemoizedEscortListTwo } from '../components/EscortListTwo';
import { CityFilter, TopFilter, UserTypeEscortFilter, ServiceFilter, EyeFilter, EthnicFilter, HeightFilter } from 'app/modules/filters/components';
import "../styles/index.scss";

export const HomePage: React.FC<unknown> = () => {
  const dispatch = useDispatch();
  const total: number = useSelector(getTotal) as number;
  const active: FiltersActiveModel = useSelector(getActiveFilters) as FiltersActiveModel;
  const [currActiveFilter, setCurrActiveFilter] = React.useState(active);
  const [currTotal, setCurrTotal] = React.useState(total);
  const [moreScorts, setMoreStorts] = React.useState<Array<EscortCardModel>>([]);
  const [showFilter, setShowFilter] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(true);
  const [hasMore, setHasMore] = React.useState(false);
  const [page, setPage] = React.useState(0);

  function applyFilter(data: any) {
    setPage(0);
    const filter = {...currActiveFilter, ...data}
    const filterData = removeNullProperties(filter);
    setCurrActiveFilter(filterData)
  }

  React.useEffect(() => {
    const cancelToken = axios.CancelToken;
    const cancelTokenSource = cancelToken.source();
    setCurrActiveFilter(currActiveFilter)
    setCurrTotal(currTotal)

    const requestEscorts = async () => {
      try {
          const {data} = await getEscorts(cancelTokenSource, {...currActiveFilter, page: page});
          dispatch(actions.fulfillFilter(data.data, data.total))
          setMoreStorts(data.data)
      } catch (error) {
        console.error(error)
      }
    }

    requestEscorts()
    return () => {
      cancelTokenSource.cancel("Axios request cancelled");
    };
    // eslint-disable-next-line
  }, [currActiveFilter, currTotal]);

  const requestMoreEscorts = async () => {
    try {
      const cancelToken = axios.CancelToken;
      const cancelTokenSource = cancelToken.source();
      const {data} = await loadMoreEscorts({...currActiveFilter, page: page}, cancelTokenSource);
      dispatch(actions.setLoadMoreEscort(page, data.data, data.total));
      setMoreStorts((listScores: Array<EscortCardModel>) => Array.from(new Set([...listScores, ...data.data].map(a => a.id)))
      .map(id => {
        return [...listScores, ...data.data].find(a => a.id === id)
      }));
      setHasMore(page < (data.totalPage - 1))
      setLoading(false);
    } catch (error) {
      console.error(error)
    }
  }

  const loadMore = () => {
    setPage((newPage: number) => newPage + 1);
    setLoading(true);
    requestMoreEscorts();
  };

  return (
    <>
      <h1 className="text-center py-4">
        Encuentra Escorts y damas de compañía en todo el sur de Chile, Las Escorts y putas más exclusivas de toda la región
      </h1>
      <div className="row">
        <CityFilter applyFilter={applyFilter} />
        <div className='col-sm-1'>
          <button className="more-filter" onClick={() => setShowFilter(!showFilter)}>Buscar por</button>
        </div>
      </div>
      {showFilter 
        && <div className="row">
          <TopFilter applyFilter={applyFilter} initialValues={currActiveFilter?.tops} />
          <UserTypeEscortFilter applyFilter={applyFilter} initialValues={currActiveFilter?.userTypes} />
          <ServiceFilter applyFilter={applyFilter} initialValues={currActiveFilter?.services}  />
          <EyeFilter applyFilter={applyFilter} initialValues={currActiveFilter?.eye}  />
          <EthnicFilter applyFilter={applyFilter} initialValues={currActiveFilter?.ethnic}  />
          <HeightFilter applyFilter={applyFilter} initialValues={currActiveFilter?.height}  />
        </div>
      }
      <InfiniteScroll
        hasMoreData={hasMore}
        isLoading={loading}
        onBottomHit={loadMore}
        loadOnMount={true}
      >
        <MemoizedEscortListTwo escorts={moreScorts} />
      </InfiniteScroll>
      {!total && <p>No hay escort disponibles con esa consulta</p>}
      {loading && <Loading><span>Cargando escorts...</span></Loading>}
    </>
  );
}

export default HomePage
