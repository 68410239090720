import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {FiltersModel, FiltersActiveModel} from '../models/FiltersModel'
import {getFilters} from '../services/FiltersService'
import axios from 'axios';

/**
 * Inteface for action with payload
 */
export interface ActionWithPayload<T> extends Action {
  payload?: T
}

/**
 * Interface for Filters state
 */
export interface IFiltersState {
  all?: FiltersModel;
  active?: FiltersActiveModel;
}

/**
 * Initial values for Filters state
 */
 const initialFiltersState: IFiltersState = {
  all: undefined,
  active: undefined,
}

/**
 * Action Types
 */
export const actionTypes = {
  FilterRequested: '[Request Filter] Action',
  SetFilter: '[Set Filter] Action',

  GetActiveFilter: '[Get Active Filter] Action',
  AddActiveFilter: '[Add Active Filter] Action',
  RemoveActiveFilter: '[Remove Active Filter] Action',
}

/**
 * Reducers
 */
export const reducer = persistReducer(
  {storage, key: 'sexisur-fe-filters', whitelist: ['all', 'active']},
  (state: IFiltersState = initialFiltersState, action: ActionWithPayload<IFiltersState>) => {
    switch (action.type) {

      case actionTypes.FilterRequested: 
      case actionTypes.GetActiveFilter:{
        return {
          ...state,
        }
      }

      case actionTypes.SetFilter: {
        const all = action.payload?.all
        return {
          ...state,
          all
        }
      }

      case actionTypes.AddActiveFilter: 
      case actionTypes.RemoveActiveFilter: {
        const active = action.payload?.active
        return {
          ...state,
          active
        }
      }

      default:
        return state
    }
  }
)

/**
 * ACTIONS TO DISPATCH
 */
export const actions = {
  requestFilter: () => ({type: actionTypes.FilterRequested,}),
  fulfillFilter: (all: FiltersModel) => ({type: actionTypes.SetFilter, payload: {all}}),
  setFilter: (all: FiltersModel) => ({type: actionTypes.SetFilter, payload: {all}}),

  getActiveFilter: () => ({type: actionTypes.FilterRequested,}),
  addActiveFilter: (active: FiltersActiveModel) => ({type: actionTypes.AddActiveFilter, payload: {active}}),
  removeActiveFilter: (active: FiltersActiveModel) => ({type: actionTypes.RemoveActiveFilter, payload: {active}}),
}

/**
 * SAGA SIDE EFFECTS
 */
export function* saga() {
  yield takeLatest(actionTypes.FilterRequested, function* filterRequested() {
    const cancelToken = axios.CancelToken;
    const cancelTokenSource = cancelToken.source();
    const {data} = yield getFilters(cancelTokenSource)
    yield put(actions.fulfillFilter(data.data))
  })
}
