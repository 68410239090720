import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import axios from 'axios';
import {call, put, takeLatest} from 'redux-saga/effects'
import {EscortCardModel} from '../models/EscortCardModel'
import {getEscorts, addFavourite, deleteFavourite} from '../services/HomeService'

/**
 * Inteface for action with payload
 */
export interface ActionWithPayload<T> extends Action {
  payload?: T
}

/**
 * Interface for Filters state
 */
export interface IHomeState {
  escorts?: Array<EscortCardModel>;
  isLoading?: boolean;
  page?: number;
  total?: number;
  totalPage?: number;
}

export interface IHomePayloadState {
  escorts?: Array<EscortCardModel>;
  idScort?: number;
  newPage?: number;
  total?: number;
}

/**
 * Initial values for Filters state
 */
 const initialHomeState: IHomeState = {
  escorts: undefined,
  isLoading: false,
  page: 0,
  totalPage: 5,
  total: 0,
}

/**
 * Action Types
 */
export const actionTypes = {
  EscortsRequested: '[Request Escorts] Action',
  SetEscorts: '[Set Escorts] Action',

  AddFavouriteRequested: '[Request Add Favourite] Action',
  AddFavourite: '[Add Favourite] Action',
  DeleteFavouriteRequested: '[Request Delete Favourite] Action',
  DeleteFavourite: '[Delete Favourite] Action',
  
  LoadMoreEscortRequested: '[Request Load more Escorts] Action',
  LoadMoreEscort: '[Load more Escorts] Action',
}

/**
 * Reducers
 */
export const reducer = persistReducer(
  {storage, key: 'sexisur-fe-home', whitelist: ['scorts']},
  (state: IHomeState = initialHomeState, action: ActionWithPayload<IHomePayloadState>) => {
    switch (action.type) {

      case actionTypes.EscortsRequested:
      case actionTypes.AddFavouriteRequested: 
      case actionTypes.DeleteFavouriteRequested:
      case actionTypes.LoadMoreEscortRequested: {
        return {
          ...state,
          isLoading: true,
        }
      }

      case actionTypes.SetEscorts: {
        const escorts = action.payload?.escorts
        const total = action.payload?.total;
        return {
          ...state,
          isLoading: false,
          total: total,
          escorts

        }
      }

      case actionTypes.LoadMoreEscort: {
        const newPage = action.payload?.newPage
        const escorts = action.payload?.escorts
        const total = action.payload?.total
        if (state.escorts && escorts ) {
          const newEscorts = [...state.escorts, ...escorts]
          const withoutDuplicate: Array<EscortCardModel> = Array.from(new Set(newEscorts.map(a => a.id)))
          .map(id => {
            return newEscorts.find(a => a.id === id)
          }) as Array<EscortCardModel>;
          return {
            ...state,
            isLoading: false,
            page: newPage,
            total: total,
            escorts: withoutDuplicate
          }
        }
        return {
          ...state,
          isLoading: false,
          page: newPage,
        }
      }

      case actionTypes.AddFavourite:
      case actionTypes.DeleteFavourite: {
        if (state.escorts) {
          const idEscort = action.payload?.idScort
          if (idEscort) {
            const index = state.escorts.findIndex((scort: any) => scort.id === idEscort);
            const newState = [...state.escorts];
            newState[index].isFavorite = !newState[index].isFavorite;
            return {
              ...state,
              isLoading: false,
              escorts: newState
            }
          }
        } 
        return {...state}
      }

      default:
        return state
    }
  }
)

/**
 * ACTIONS TO DISPATCH
 */
export const actions = {
  // List escorts
  requestFilter: () => ({type: actionTypes.EscortsRequested,}),
  fulfillFilter: (escorts: Array<EscortCardModel>, total: number) => ({type: actionTypes.SetEscorts, payload: {escorts, total}}),
  setFilter: (escorts: Array<EscortCardModel>, total: number) => ({type: actionTypes.SetEscorts, payload: {escorts, total}}),
  
  // Load more escorts
  requestLoadMoreEscort: (newPage: number) => ({type: actionTypes.LoadMoreEscortRequested, payload: {newPage}}),
  setLoadMoreEscort: (newPage: number, escorts: Array<EscortCardModel>, total: number) => ({type: actionTypes.LoadMoreEscort, payload: {newPage, escorts, total}}),

  // Favourite
  requestAddFavourite: (idScort: number) => ({type: actionTypes.AddFavouriteRequested, payload: {idScort}}),
  setAddFavourite: (idScort: number) => ({type: actionTypes.AddFavourite, payload: {idScort}}),
  requestDeleteFavourite: (idScort: number) => ({type: actionTypes.DeleteFavouriteRequested, payload: {idScort}}),
  setDeleteFavourite: (idScort: number) => ({type: actionTypes.DeleteFavourite, payload: {idScort}}),
}

/**
 * SAGA SIDE EFFECTS
 */
export function* saga() {

  // Starts escortsRequested on each dispatched [Request Escorts] action
  yield takeLatest(actionTypes.EscortsRequested, function* escortsRequested() {
    const cancelToken = axios.CancelToken;
    const cancelTokenSource = cancelToken.source();
    const {data} = yield getEscorts(cancelTokenSource)
    yield put(actions.fulfillFilter(data.data, data.total))
  })

  // Starts addFavouriteRequested on each dispatched [Request Add Favourite] action
  yield takeLatest(actionTypes.AddFavouriteRequested, function* addFavouriteRequested(action: ActionWithPayload<IHomePayloadState>) {
    const idScort = action?.payload?.idScort
    if (idScort) {
      try {
        yield call(addFavourite, idScort)
        yield put(actions.setAddFavourite(idScort))
      } catch (error) {
        console.log(error);
      }
    }
  })

  // Starts deleteFavouriteRequested on each dispatched [Request Delete Favourite] action
  yield takeLatest(actionTypes.DeleteFavouriteRequested, function* deleteFavouriteRequested(action: ActionWithPayload<IHomePayloadState>) {
    const idScort = action?.payload?.idScort
    if (idScort) {
      yield deleteFavourite(idScort)
      yield put(actions.setDeleteFavourite(idScort))
    }
      
  })
}
