import React from 'react'
import axios from 'axios';
import {connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from 'app/themes/_sexisur/layout/core'
import * as home from './HomeRedux';
import {getEscorts} from '../services/HomeService'
import {RootState} from 'app/setup'

const mapState = (state: RootState) => ({home: state.home})
const connector = connect(mapState, home.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const HomeInit: React.FC<PropsFromRedux> = (props) => {
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = React.useState(true)

  // We should request filters before rendering the application
  React.useEffect(() => {
    const cancelToken = axios.CancelToken;
    const cancelTokenSource = cancelToken.source();
    const requestEscorts = async () => {
      try {
          const {data} = await getEscorts(cancelTokenSource);
          dispatch(props.fulfillFilter(data.data, data.total))
      } catch (error) {
        console.error(error)
      } finally {
        setShowSplashScreen(false)
      }
    }

    requestEscorts();
    return () => {
      cancelTokenSource.cancel("Axios request cancelled");
    };
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(HomeInit)
