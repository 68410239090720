/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG } from 'app/themes/_sexisur/helpers';

type Props = {
  phoneNumber: number;
}

const WhatsAppButton: React.FC<Props> = ({phoneNumber}) => {

  const handleSend = () => {
    window.open(`https://wa.me/${phoneNumber}?text=Hola, he visto tu anunció en SexiSur y quiero quedar contigo`, 'Sexisur', 'noopener');
  }

  return (
    <div className="whatsapp-wrapper">
      <div className="ring">
          <div className="ring-effect-phone ring-effect-green ring-effect-show">
              <div className="ring-effect-ph-circle"></div>
              <div className="ring-effect-ph-circle-fill"></div>
              <div className="ring-effect-ph-img-circle">
                <button className="ring-button" type="button" onClick={handleSend}>
                  <KTSVG path='/media/icons/duotone/Communication/Chat.svg' className='svg-icon-3x' />
                </button>
              </div>
          </div>
      </div>
    </div>
  )
}

export {WhatsAppButton}
