import clsx from 'clsx'
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {RootState} from 'app/setup';
import {Link} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux';
import {ChatButtonPage} from 'prom-lib-chat';
import { UserModel } from 'app/modules/auth/models/UserModel';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-3x';

const Topbar: React.FC<unknown> = () => {
  const isAuthorized: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}

      {/*begin::Chat*/}
      {!isAuthorized &&
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div className={clsx('btn btn-icon btn-active-light-primary position-relative', toolbarButtonHeightClass)}>
          <Link to='/auth' className='link-primary fw-bolder'>
            <KTSVG path="/media/icons/duotone/Communication/Group-chat.svg" className={toolbarButtonIconSizeClass} />
          </Link>
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        </div>
      </div>
      }
      {isAuthorized && <ChatButtonPage />}
      {/*end::Chat*/}


      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          {isAuthorized
            ? <img src={isAuthorized?.mainImage} alt={isAuthorized?.alias} />
            : <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='Avatar por defecto' />
          }
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}
    </div>
  )
}

export {Topbar}
