import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {FallbackView} from 'app/themes/_sexisur/partials'

export function PrivateRoutes() {
  const ClientProfilePage = React.lazy(() => import('../modules/profiles/client/pages/ClientProfilePage'));
  const EscortProfilePage = React.lazy(() => import('../modules/profiles/escort/pages/EscortProfilePage'));
  const LogoutPage = React.lazy(() => import('../modules/auth/pages/LogoutPage'));

  return (
    <React.Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/profile/client' component={ClientProfilePage} />
        <Route path='/profile/escort/edit/:id' component={EscortProfilePage} />
        <Route path='/logout' component={LogoutPage} />
      </Switch>
    </React.Suspense>
  )
}
