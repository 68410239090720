import React from 'react';
import ReactDOM from 'react-dom';

// Redux
// https://github.com/rt2zz/redux-persist
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from 'react-redux';
import * as _redux from './app/setup';
import store, {persistor} from './app/setup/redux/Store';

// Axios
import axios from 'axios';

// Apps
import {App} from './app/App';

// Themes
import {SexisurI18nProvider} from './app/themes/_sexisur/i18n/Sexisuri18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_sexisur/assets/sass/style.dark.scss'
 **/
import './app/themes/_sexisur/assets/sass/style.dark.scss'
// import './app/themes/_sexisur/assets/sass/style.scss'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Sexisur mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

if (process.env.REACT_APP_MOCK_ENABLED === 'true') {
  _redux.mockAxios(axios)
}
/**
 * Inject Sexisur interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store)

ReactDOM.render(
  <SexisurI18nProvider>
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
        <App basename={PUBLIC_URL} />
      </PersistGate>
    </Provider>
  </SexisurI18nProvider>,
  document.getElementById('root')
)
