import { createSelector } from "reselect";

import { RootState } from "../../../setup/redux/RootReducer";
import { EscortCardModel } from "../models/EscortCardModel";

// const getPending = (state: RootState) => state.home.pending;

const selectAllEscorts = (state: RootState) => state.home.escorts;
const selectPage = (state: RootState) => state.home.page;
const selectTotalPage = (state: RootState) => state.home.totalPage;
const selectTotal = (state: RootState) => state.home.total;

// const getError = (state: RootState) => state.home.error;

// export const getPendingSelector = createSelector(getPending, (pending) => pending);

export const getAllEscorts = createSelector(
    selectAllEscorts,
    (scorts) => scorts
);

export const getPage = createSelector(
    selectPage,
    (page) => page
);

export const getTotal = createSelector(
    selectTotal,
    (total) => total
);

export const getTotalPage = createSelector(
    selectTotalPage,
    (totalPage) => totalPage
);

export const getTopOneEscorts = createSelector(
    selectAllEscorts,
    (scorts) => scorts?.filter((escort: EscortCardModel) => escort.top.slug === "top-1")
    // .sort(function (x, y) {
    //     return x.orderInTop - y.orderInTop;
    // })
);

export const getTopTwoEscorts = createSelector(
    selectAllEscorts,
    (scorts) => scorts?.filter((escort: EscortCardModel) => escort.top.slug === "top-2")
    // .sort(function (x, y) {
    //     return x.orderInTop - y.orderInTop;
    // })
);

export const getTopThreeEscorts = createSelector(
    selectAllEscorts,
    (scorts) => scorts?.filter((escort: EscortCardModel) => escort.top.slug === "top-3")
    // .sort(function (x, y) {
    //     return x.orderInTop - y.orderInTop;
    // })
);

// export const getErrorSelector = createSelector(getError, (error) => error);
