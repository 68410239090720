export const colourStylesSelect = {
  container: (styles: any) => ({
    ...styles,
    background: "#1b1b29",
    ':focus': {
      ...styles[':focus'],
      backgroundColor: "rgb(222, 53, 11, 0.6)",
    },
  }),
  menu: (styles: any) => ({
    ...styles,
    background: "#1b1b29",
  }),
  control: (styles: any) => ({ 
    ...styles,
    background: "#1b1b29", 
  }),
  option: (styles: any) => {
    return {
      ...styles,
      primary25: 'hotpink',
      primary: 'black',
      ':hover': {
        ...styles[':hover'],
        backgroundColor: "rgb(222, 53, 11, 0.6)",
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: "rgb(222, 53, 11, 0.6)",
      },
    };
  },
  input: (styles: any) => ({ 
    ...styles,
    background: "#1b1b29",
    color: "#CDCDDE",
    ':focus': {
      ...styles[':focus'],
      backgroundColor: "rgb(222, 53, 11, 0.6)",
    },
  }),
  placeholder: (styles: any) => ({ ...styles }),
  singleValue: (styles: any) => ({ 
    ...styles,
    color: "#CDCDDE",
  }),
};

export const themeSelect: any = (theme: any) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary25: "rgb(222, 53, 11, 0.6)",
    primary: 'black',
  },
});
