import {EscortCardModel} from '../models/EscortCardModel';
import { toAbsoluteUrl } from 'app/themes/_sexisur/helpers';

export class HomeTableMock {
  public static table: Array<EscortCardModel> = [
    {
      "phone": 598940013,
      "orderInTop": 1,
      "age": 27,
      "id": 5,
      "username": "+598940013",
      "register": "21-08-2021",
      "alias": "Analia",
      "slug": "105405fe568793fa7c478d550f9fb4a2",
      "userType": 2,
      "role": "ROLE_SCORT",
      "mainImage": "https://localhost/images/scorts/105405fe568793fa7c478d550f9fb4a2/images/61212c4f21555.jpg",
      "city": {
          "id": "82383295d500d5b72df24e7d0725804d",
          "name": "Providencia",
          "slug": "providencia"
      },
      "stars": 0,
      "top": {
          "id": "3605d13ff0d89004410321960d999c9c",
          "name": "Top 1",
          "slug": "top-1",
          "position": 1
      },
      "imageUrl": toAbsoluteUrl('/media/scorts/00d08ecadc93f58424a3a50368cf824a/images/61212c6b5a6b4.jpg'),
      "isVideo": false,
      "videoUrl": "",
      "isFavorite": false,
      "isFollowing": false
    },
    {
      "phone": 598940016,
      "orderInTop": 2,
      "age": 29,
      "id": 8,
      "username": "+598940016",
      "register": "21-08-2021",
      "alias": "Niky",
      "slug": "ad7f8ef238ea8832e6a3af76d92168f2",
      "userType": 2,
      "role": "ROLE_SCORT",
      "mainImage": "https://localhost/images/scorts/ad7f8ef238ea8832e6a3af76d92168f2/images/61212c50c97d3.jpg",
      "city": {
          "id": "f9efc0313c58faa1cb23b871f72b363e",
          "name": "Quellón",
          "slug": "quellon"
      },
      "stars": 0,
      "top": {
          "id": "3605d13ff0d89004410321960d999c9c",
          "name": "Top 1",
          "slug": "top-1",
          "position": 1
      },
      "imageUrl": toAbsoluteUrl('/media/scorts/1dc8d3128f4f19b446c525d5670f73f7/images/61212c63799bc.jpg'),
      "isVideo": false,
      "videoUrl": "",
      "isFavorite": false,
      "isFollowing": false
    },
    {
      "phone": 598940017,
      "orderInTop": 3,
      "age": 22,
      "id": 9,
      "username": "+598940017",
      "register": "21-08-2021",
      "alias": "KataDominicana",
      "slug": "37e7dc0b11bd3f306782277a88b90a5e",
      "userType": 2,
      "role": "ROLE_SCORT",
      "mainImage": "https://localhost/images/scorts/37e7dc0b11bd3f306782277a88b90a5e/images/61212c515c6f0.jpg",
      "city": {
          "id": "253b596f4a2f64ef08cf69bd1ba2e939",
          "name": "Chillán",
          "slug": "chillan"
      },
      "stars": 0,
      "top": {
          "id": "3605d13ff0d89004410321960d999c9c",
          "name": "Top 1",
          "slug": "top-1",
          "position": 1
      },
      "imageUrl": toAbsoluteUrl('/media/scorts/1e8baca83ddaecb76b1dedb46bf5330f/images/61212c6895c75.jpg'),
      "isVideo": false,
      "videoUrl": "",
      "isFavorite": false,
      "isFollowing": false
    },
    {
      "phone": 598940023,
      "orderInTop": 4,
      "age": 28,
      "id": 15,
      "username": "+598940023",
      "register": "21-08-2021",
      "alias": "Amelia",
      "slug": "156757cecd5c4f94c8864f685678d7df",
      "userType": 1,
      "role": "ROLE_SCORT",
      "mainImage": "https://localhost/images/scorts/156757cecd5c4f94c8864f685678d7df/images/61212c54a6210.jpg",
      "city": {
          "id": "d68a8c3fc908b60ef427d4557409b9f5",
          "name": "Calama",
          "slug": "calama"
      },
      "stars": 0,
      "top": {
          "id": "3605d13ff0d89004410321960d999c9c",
          "name": "Top 1",
          "slug": "top-1",
          "position": 1
      },
      "imageUrl": toAbsoluteUrl('/media/scorts/2b0c2f42202d1f5ed4a256cb6fd5789c/images/61212c5aa0156.jpg'),
      "isVideo": false,
      "videoUrl": "",
      "isFavorite": false,
      "isFollowing": false
    },
    {
      "phone": 598940025,
      "orderInTop": 5,
      "age": 31,
      "id": 17,
      "username": "+598940025",
      "register": "21-08-2021",
      "alias": "Angel",
      "slug": "e898350b6e8037292871cc034d2900da",
      "userType": 1,
      "role": "ROLE_SCORT",
      "mainImage": "https://localhost/images/scorts/e898350b6e8037292871cc034d2900da/images/61212c55c019c.jpg",
      "city": {
          "id": "d7157826450761b36f2bff8218d1d13e",
          "name": "San Fernando",
          "slug": "san-fernando"
      },
      "stars": 0,
      "top": {
          "id": "3605d13ff0d89004410321960d999c9c",
          "name": "Top 1",
          "slug": "top-1",
          "position": 1
      },
      "imageUrl": toAbsoluteUrl('/media/scorts/2ced6ab8a4acea086569a2cd5f9d66cb/images/61212c56d5bb6.jpg'),
      "isVideo": false,
      "videoUrl": "",
      "isFavorite": false,
      "isFollowing": false
    },
    {
      "phone": 598940028,
      "orderInTop": 6,
      "age": 18,
      "id": 20,
      "username": "+598940028",
      "register": "21-08-2021",
      "alias": "Lucas",
      "slug": "25ed3be8079f660869e74145583e1680",
      "userType": 3,
      "role": "ROLE_SCORT",
      "mainImage": "https://localhost/images/scorts/25ed3be8079f660869e74145583e1680/images/61212c5765602.jpg",
      "city": {
          "id": "cae6b673647eee5c295ef77e151c6631",
          "name": "Niebla",
          "slug": "niebla"
      },
      "stars": 0,
      "top": {
          "id": "3605d13ff0d89004410321960d999c9c",
          "name": "Top 1",
          "slug": "top-1",
          "position": 1
      },
      "imageUrl": toAbsoluteUrl('/media/scorts/2f00787ef13781d2152e8ca04c652e1e/images/61212c5e6e067.jpg'),
      "isVideo": false,
      "videoUrl": "",
      "isFavorite": false,
      "isFollowing": false
    },
    {
      "phone": 598940030,
      "orderInTop": 7,
      "age": 45,
      "id": 22,
      "username": "+598940030",
      "register": "21-08-2021",
      "alias": "Tamy",
      "slug": "dd11620dc41174be688ee871e5803289",
      "userType": 2,
      "role": "ROLE_SCORT",
      "mainImage": "https://localhost/images/scorts/dd11620dc41174be688ee871e5803289/images/61212c587894f.jpg",
      "city": {
          "id": "88016775a7a8565eb72770bd45843085",
          "name": "Puerto Varas",
          "slug": "puerto-varas"
      },
      "stars": 0,
      "top": {
          "id": "3605d13ff0d89004410321960d999c9c",
          "name": "Top 1",
          "slug": "top-1",
          "position": 1
      },
      "imageUrl": toAbsoluteUrl('/media/scorts/4d71a261c5456364f172cc63c41ab92d/images/61212c6021164.jpg'),
      "isVideo": false,
      "videoUrl": "",
      "isFavorite": false,
      "isFollowing": false
    },
    {
      "phone": 598940031,
      "orderInTop": 8,
      "age": 36,
      "id": 23,
      "username": "+598940031",
      "register": "21-08-2021",
      "alias": "Tamii",
      "slug": "7aa0278c5348e9ba5bc74a688f3653de",
      "userType": 2,
      "role": "ROLE_SCORT",
      "mainImage": "https://localhost/images/scorts/7aa0278c5348e9ba5bc74a688f3653de/images/61212c590a83a.jpg",
      "city": {
          "id": "f4d160bb63c20cdbdf1b6d779e915753",
          "name": "La Serena",
          "slug": "la-serena"
      },
      "stars": 0,
      "top": {
          "id": "3605d13ff0d89004410321960d999c9c",
          "name": "Top 1",
          "slug": "top-1",
          "position": 1
      },
      "imageUrl": toAbsoluteUrl('/media/scorts/7aa0278c5348e9ba5bc74a688f3653de/images/61212c590a83a.jpg'),
      "isVideo": false,
      "videoUrl": "",
      "isFavorite": false,
      "isFollowing": false
    },
    {
      "phone": 598940036,
      "orderInTop": 9,
      "age": 24,
      "id": 28,
      "username": "+598940036",
      "register": "21-08-2021",
      "alias": "Fernando",
      "slug": "eaeea6dba1311cc2870019ae2d97d150",
      "userType": 3,
      "role": "ROLE_SCORT",
      "mainImage": "https://localhost/images/scorts/eaeea6dba1311cc2870019ae2d97d150/images/61212c5bb66a0.jpg",
      "city": {
          "id": "5421ca9ceaa6e855aefc4852dbd0c852",
          "name": "Curicó",
          "slug": "curico"
      },
      "stars": 0,
      "top": {
          "id": "3605d13ff0d89004410321960d999c9c",
          "name": "Top 1",
          "slug": "top-1",
          "position": 1
      },
      "imageUrl": toAbsoluteUrl('/media/scorts/8f4c1a1b585877d81608d4c5763ae80a/images/61212c6c789e6.jpg'),
      "isVideo": false,
      "videoUrl": "",
      "isFavorite": false,
      "isFollowing": false
    },
    {
      "phone": 598940037,
      "orderInTop": 10,
      "age": 23,
      "id": 29,
      "username": "+598940037",
      "register": "21-08-2021",
      "alias": "ABY",
      "slug": "cdd06761c3db3c7f8d3cfe9e22a55c08",
      "userType": 2,
      "role": "ROLE_SCORT",
      "mainImage": "https://localhost/images/scorts/cdd06761c3db3c7f8d3cfe9e22a55c08/images/61212c5c47f4c.jpg",
      "city": {
          "id": "3c95f70c035f237702dc4991802cea40",
          "name": "Natales",
          "slug": "natales"
      },
      "stars": 0,
      "top": {
          "id": "3605d13ff0d89004410321960d999c9c",
          "name": "Top 1",
          "slug": "top-1",
          "position": 1
      },
      "imageUrl": "https://localhost/images/scorts/cdd06761c3db3c7f8d3cfe9e22a55c08/images/61212c5c47f4c.jpg",
      "isVideo": false,
      "videoUrl": "",
      "isFavorite": false,
      "isFollowing": false
    }
  ]
}
