import React from 'react'
import './style.scss'

export const Loading: React.FC = ({children}) => {
  return (
    <div className="wrapper-loading">
        <div className="circle-loading"></div>
        <div className="circle-loading"></div>
        <div className="circle-loading"></div>
        <div className="shadow-loading"></div>
        <div className="shadow-loading"></div>
        <div className="shadow-loading"></div>
        {children}
    </div>
  )
}
