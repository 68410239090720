/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

type Props = {
  phoneNumber: number;
}

export const CallButton: React.FC<Props> = ({phoneNumber}) => {

  return (
    <div className="call-wrapper">
      <div className="ring">
          <div className="ring-effect-phone ring-effect-green ring-effect-show">
              <div className="ring-effect-ph-circle"></div>
              <div className="ring-effect-ph-circle-fill"></div>
              <a className="ring-button" href={`tel:+${phoneNumber}`}>
                <div className="ring-effect-ph-img-circle">
                  {' '}
                </div>
              </a>
          </div>
      </div>
    </div>
  )
}
