/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import clsx from "clsx";
import { EscortCardModel } from '../models/EscortCardModel';
import { EscortCardTwo } from './EscortCardTwo';

type Props = {
    escorts: Array<EscortCardModel>;
}

const renderTemplate = (moreEscorts: Array<EscortCardModel>) => {
    const top1 = moreEscorts?.filter((escort: EscortCardModel) => escort.top.slug === "top-1")
    // .sort(function (x, y) {
    //   return x.orderInTop - y.orderInTop;
    // })
    const escortsCardTopOne = top1 && top1.map((escort: EscortCardModel) =>
    <EscortCardTwo 
      key={escort?.phone}
      className={clsx({'escort-card top-one': true,})}
      escort={escort}
    />);
    const totalEscortsCardTopOne = escortsCardTopOne.length > 0

    const top2 = moreEscorts?.filter((escort: EscortCardModel) => escort.top.slug === "top-2")
    // .sort(function (x, y) {
    //   return x.orderInTop - y.orderInTop;
    // })
    const escortsCardTopTwo = top2 && top2.map((escort: EscortCardModel) =>
    <EscortCardTwo 
      key={escort?.phone}
      className={clsx({'escort-card top-two': true,})}
      escort={escort}
    />);
    const totalEscortsCardTopTwo = escortsCardTopTwo.length > 0

    const top3 = moreEscorts?.filter((escort: EscortCardModel) => escort.top.slug === "top-3")
    // .sort(function (x, y) {
    //   return x.orderInTop - y.orderInTop;
    // })
    const escortsCardTopThree = top3 && top3.map((escort: EscortCardModel) =>
    <EscortCardTwo 
      key={escort?.phone}
      className={clsx({'escort-card top-three': true,})}
      escort={escort}
    />);
    const totalEscortsCardTopThree = escortsCardTopThree.length > 0

    return (
      <>
        {totalEscortsCardTopOne && (<ul className="grid-1 cards">{escortsCardTopOne}</ul>)}
        {totalEscortsCardTopTwo && (<ul className="grid-2 cards">{escortsCardTopTwo}</ul>)}
        {totalEscortsCardTopThree && (<ul className="grid-3 cards">{escortsCardTopThree}</ul>)}
      </>
    )
}

export const EscortListTwo: React.FC<Props> = ({ escorts }) => {
    return (
      <div className="wrapper-card">
        {renderTemplate(escorts)}
      </div>
    );
}
  
export const MemoizedEscortListTwo = React.memo(EscortListTwo);
